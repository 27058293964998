<template>
    <div class="bpbj">
        <div class="c1" >

            <div v-for="(c1Item,index) in catalogs" class="item" :key="'c1'+index" :class="currentId==c1Item?'active':''" @click="select(c1Item)">
                <div class="icon"></div>
                <div class="txt">{{ c1Item }}</div>
            </div>


<!--            <div class="item" :class="currentId==1?'active':''" @click="select(1)">-->
<!--                <div class="icon"></div>-->
<!--                <div class="txt">气动元件</div>-->
<!--            </div>-->

<!--            <div class="item" :class="currentId==2?'active':''" @click="select(2)">-->
<!--                <div class="icon"></div>-->
<!--                <div class="txt">液压器件</div>-->
<!--            </div>-->
        </div>
        <div class="c2">

            <div v-for="(c2Item,index) in subCatalogs" :key="'c2'+index" v-show="currentId==c2Item.key" class="panel">
                <div v-for="(pItem1,index) in c2Item.data" :key="'pItem'+index" class="pItem">
                    <img  :src="pItem1.logo"/>
                    <div class="txt">
                        <div class="cname">{{ pItem1.name }}</div>
                        <div class="subitem">
                            <div v-for="(nameItem,index) in pItem1.members " :key="'n'+index" @click="toDetail(nameItem)">{{ nameItem.name }}</div>
                        </div>
                    </div>
                </div>
            </div>


<!--            <div class="panel" v-if="currentId==1">-->
<!--                <div class="pItem">-->
<!--                    <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLjuSjpjA61frZ5znFJRUMfLwXmFeZvFNbxE6ucRdPibJPlMLaTOQK8S9XibFicqdRx4eRrF9SJibVKFg/132"/>-->
<!--                    <div class="txt">西蒙子</div>-->
<!--                </div>-->
<!--                <div class="pItem">-->
<!--                    <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLjuSjpjA61frZ5znFJRUMfLwXmFeZvFNbxE6ucRdPibJPlMLaTOQK8S9XibFicqdRx4eRrF9SJibVKFg/132"/>-->
<!--                    <div class="txt">-->
<!--                        <div class="cname">西蒙子</div>-->
<!--                        <div class="subitem">-->
<!--                            <div>苏州安徒司有限公司</div>-->
<!--                            <div>苏州安徒司有限公司</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="pItem">-->
<!--                    <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLjuSjpjA61frZ5znFJRUMfLwXmFeZvFNbxE6ucRdPibJPlMLaTOQK8S9XibFicqdRx4eRrF9SJibVKFg/132"/>-->
<!--                    <div class="txt">西蒙子</div>-->
<!--                </div>-->
<!--            </div>-->

            <!-- 返回home-->
            <div class="reqlist_back_home" v-on:click="toHome">
                <img src="assets/back_home.png"/>
            </div>
        </div>

        <Popup v-model="popupVisible" position="bottom">
            <div class="PopupShow">
                <div class="item">
                    <div>公司名称：{{p1}} </div>
                    <div>公司地址：{{p2}}</div>
                    <div>联系方式：{{p3}}</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
    import {Popup} from 'mint-ui';
    import keys from "../../xyg/utils/keys";

    export default {
        name: "BeiPingBeiJian.vue",
        data(){
            return {
                catalogs:[],
                subCatalogs:[],
                currentId:'',
                popupVisible:false,
                p1:'',
                p2:'',
                p3:'',
            }
        },
        components:{
            Popup
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                let reqUrl="/api/brand";
                this.$post(reqUrl,null)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let data=rsp.data;
                            data.forEach(t=>{
                                let catalog=t.category;
                                let brands=t.brands;
                                this.catalogs.push(catalog);
                                if(this.currentId=='') this.currentId= catalog;
                                if(brands!=null && brands!=undefined){
                                    let brand={
                                        key:catalog,
                                        data:brands,
                                    }
                                    this.subCatalogs.push(brand);
                                }
                            })
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            select(id){
                this.currentId=id;
            },
            toDetail(detail){

                let openId=localStorage.getItem(keys.openId);
                if(openId==null || openId==undefined)return;

                this.p1=detail.name;
                this.p2=detail.address;
                this.p3=detail.phone;
                this.popupVisible=true;
            },
            toHome(){
                this.$router.push({path:'/'});
            }
        }
    }
</script>

<style scoped>
    .bpbj{
        display: flex;
        flex-direction: row;
        width: 750px;
        padding-top: 30px;
        height: 100vh;
    }

    .bpbj .c1{
        width: 200px;
    }

    .bpbj .c1 .item{
        font-size: 28px;
        background-color: white;
        height: 88px;
        margin-bottom: 4px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bpbj .c1 .active{
        color:#226DF8;
    }

    .active .icon{
        width: 10px;
        height: 88px;
        background-color: #226DF8;
        position: absolute;
        left: 0;
    }

    .bpbj .c1 .item .txt{

    }

    .bpbj .c2{
        flex:1;
        overflow: scroll;
        margin-left: 4px;
        background-color: white;
    }
    .c2 .panel{
        flex:1;
        padding-top: 20px;
    }

    .panel .pItem{
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    .pItem img{
        width: 110px;
        height: 40px;
    }

    .pItem .txt{
        font-size: 28px;
        letter-spacing: 2px;
        margin-left: 6px;
    }

    .pItem .txt .cname{
    }

    .pItem .subitem{
    }
    .pItem .subitem div{
        margin-top: 20px;
    }

    .PopupShow{
        background-color: white;
        border-radius: 10px;
        width: 750px;
        font-size: 28px;
    }

    .PopupShow .item{
        margin-top: 20px;
        margin-left: 20px;
    }

    .PopupShow .item div{
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }

</style>
